import React from 'react';

import Layout from '../../components/MenuLayout';
import { FilesView } from '../../components/File/FilesView';

export default () => (
  <Layout>
    <FilesView />
  </Layout>
);
