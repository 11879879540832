import styled from 'styled-components';

export const SectionWrapper = styled.div`
  padding-bottom: 3%;
`;

export const SectionContainer = styled.div`
  padding: 1em 0;
  height: calc(100vh - 125px);
`;

export const SectionHeader = styled.div`
  margin-bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
